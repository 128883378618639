import { Controller } from 'stimulus'

export default class extends Controller {
  
  static targets = [ 'burger', 'menu', 'logo' ]

  // element: HTMLElement
  logoTarget: HTMLImageElement
  burgerTarget: HTMLElement
  menuTarget: HTMLDivElement
  navLinks: NodeListOf<HTMLLinkElement>

  svgCross: string = `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>`
  svgBurger: string = `<svg class="fill-current" viewBox="0 0 20 20" width="28" height="28" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path fill="currentColor" d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>`

  connect () {
    this.navLinks = this.element.querySelectorAll('.nav-link')
    document.addEventListener("scroll", (e) => {
      /*Apply classes for slide in bar*/
      let scrollpos : Number = window.scrollY
      if (scrollpos > 10) {
        this.element.classList.remove("normal", "gradient")
        this.element.classList.add("inverted")
        this.toggleNavLinks();
      } else {
        this.element.classList.remove("inverted")
        this.element.classList.add("normal", "gradient")
        this.toggleNavLinks();
      }
    })
  }

  toggleNavLinks() {
    for (var i = 0; i < this.navLinks.length; i++) {
      if (this.element.classList.contains("inverted")) {
        this.navLinks[i].classList.add("inverted")
        this.navLinks[i].classList.remove("normal")
      } else {
        this.navLinks[i].classList.add("normal")
        this.navLinks[i].classList.remove("inverted")
      }
    }
  }

  toggleMenu(event) {
    this.menuTarget.classList.toggle("hidden")

    if (this.menuTarget.classList.contains("hidden")) {
      this.burgerTarget.innerHTML = this.svgBurger
    } else {
      this.burgerTarget.innerHTML = this.svgCross
    }
    for (var i = 0; i < this.navLinks.length; i++) {
      this.navLinks[i].classList.toggle("ringed")
    }
  }


}